// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
//export const environment = {
//  production: true,
//  origin: {
//    version1: "https://localhost:5001",
//    version2: "https://localhost:5001",
//  },
//  scormUrl: "https://scorm-api.westeurope.dev.cloud.playit.training"
//};

export const environment = {
  production: true,
  origin: {
    version1: "https://dev.api.playitsafe.eu",
    version2: "https://pits-api-dev.azurewebsites.net",
  },
  scormUrl: "https://scorm-api.dev.cloud.playit.training"
};